import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from 'src/app/info-dialog/info-dialog.component';
import { CycleCountItemToRecount } from 'src/app/models/cycle-count-item-to-recount.model';
import { DataService } from 'src/app/services/data.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-cycle-count-items-to-recount',
  templateUrl: './cycle-count-items-to-recount.component.html',
  styleUrls: ['./cycle-count-items-to-recount.component.scss']
})
export class CycleCountItemsToRecountComponent implements OnInit {

  dataSource: MatTableDataSource<CycleCountItemToRecount>;
  selection = new SelectionModel<CycleCountItemToRecount>(true, []);

  columns: string[] = [
    'select', 
    'quantity', 
    'itemNumber', 
    'description', 
    'location', 
    'lot'
  ];

  constructor(
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loaderService: LoaderService,
    public dialogRef: MatDialogRef<CycleCountItemsToRecountComponent>,
    public dialog: MatDialog,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadItemsToRecount();
  }

  loadItemsToRecount(): void {
    this.dataService.getItemsToRecount(this.data.id).subscribe((response) => {
      const hasResponse = response !== null && response !== undefined || response.length > 0;
      if (hasResponse) {
        this.dataSource = new MatTableDataSource(response);
      } else {
        this.showInfoDialog(`messages.noItemsFoundToRecount`);
      }
    });
  }

  recountItems(): void {
    if (this.hasSelectedItems()) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: this.translateService.instant('messages.recount', { itemId: this.data.id, itemDescription: this.data.description })
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const cycleId = this.data.id;
          const itemsToRecount = this.selection.selected;
          this.dataService.recountItems(cycleId, itemsToRecount).subscribe((response) => {
            this.loadItemsToRecount();
            this.dialogRef.close();
          }, (error) => {
            console.log(error);
          });
        }
      });
    } else {
      this.showInfoDialog(`messages.noItemsSelectedToRecount`);
    }
  }
  
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  showInfoDialog(message: string): void {
    this.dialog.open(InfoDialogComponent, {
      data: {
        message: this.translateService.instant(message)
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  hasSelectedItems(): boolean {
    if (this.selection && this.selection.selected) {
      return this.selection.selected.length > 0;
    }
    return false;
  }

}
