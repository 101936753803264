import { DataService } from './../../services/data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ExportCycleCountDialogComponent } from '../export-cycle-count-dialog/export-cycle-count-dialog.component';
import { CycleCountListItem } from 'src/app/models/cycle-count-list-item.model';
import { AuthService } from 'src/app/services/auth.service';
import { CycleCountItemsComponent } from '../cycle-count-items/cycle-count-items.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CycleCountItemsToRecountComponent } from '../cycle-count-items-to-recount/cycle-count-items-to-recount.component';

@Component({
  selector: 'app-exported-cycle-counts',
  templateUrl: './exported-cycle-counts.component.html',
  styleUrls: ['./exported-cycle-counts.component.scss']
})
export class ExportedCycleCountsComponent implements OnInit {

  filter: string;
  displayedColumns: string[] = ['id', 'description'];
  dataSource: MatTableDataSource<CycleCountListItem>;
  subscription: Subscription;
  filterChange: Subject<string> = new Subject<string>();  

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private _dataService: DataService,
    private _authService: AuthService,
    private _translateService: TranslateService,
    public dialog: MatDialog) {
    this.filterChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe((value: string) => {
        this.dataSource.filter = value ? value.trim().toLowerCase() : '';
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      });
  }

  ngOnInit() {  
    this.loadData();
  }
  
  txtFilterChange(value: string) {
    this.filterChange.next(value);
  }

  clearFilter() {
    this.filter = '';
    this.filterChange.next(this.filter);
  }

  loadData() {
    this.subscription = this._dataService.getExportedCycleCounts(this._authService.user.id).subscribe((response) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openCycleCount(cycleCount: CycleCountListItem) {
    const dialogRef = this.dialog.open(CycleCountItemsComponent, {
      data: cycleCount,
      autoFocus: false,
      width: '100vw',
      height: '650px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        setTimeout(() => {
          this.loadData();
        }, 100);
      }
    });
  }

  recount(item: CycleCountListItem) {
    const dialogRef = this.dialog.open(CycleCountItemsToRecountComponent, {
      width: '100vw',
      data: item
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  resend(item: CycleCountListItem) {
    this.dialog.open(ExportCycleCountDialogComponent, {
      width: '75%',
      data: item
    });
  }

}
