import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  @Input() source: string;
  showImage = false;
  
  constructor() { }

  ngOnInit(): void {    
  }

  show(): void {
    this.showImage = true;
  }

  hide(): void {
    this.showImage = false;
  }

}
