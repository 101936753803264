<div class="container-fluid">

    <h1 mat-dialog-title>
        {{'lot' | translate}} {{data.id}} - {{data.description}} - {{data.location}}
    </h1>

    <div mat-dialog-content>
        <mat-tab-group animationDuration="0ms">
            <mat-tab label="{{'pointed' | translate}}">
                <div class="pointed-items">
                    <table mat-table [dataSource]="pointedItemsDataSource" class="w-100">

                        <ng-container matColumnDef="itemNumber">
                            <th mat-header-cell *matHeaderCellDef>{{'columns.code' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.itemNumber}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef>{{'columns.description' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center">
                                    <span>{{element.description}}</span>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef>{{'columns.location' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center">
                                    <span>{{element.location}}</span>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="countedQuantity">
                            <th mat-header-cell *matHeaderCellDef>{{'columns.countedQuantity' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center">
                                    <span class="flex-grow-1">{{element.countedQuantity}}</span>
                                    <button *ngIf="!data.sent" mat-icon-button color="primary" (click)="editPointedItem(element)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <mat-icon *ngIf="data.sent" class="ml-2 text-muted lock">lock</mat-icon>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="lot">
                            <th mat-header-cell *matHeaderCellDef>{{'columns.batch' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center">
                                    <span class="flex-grow-1">{{element.lot}}</span>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="pointedItemsColumns; sticky: true"></tr>
                        <tr mat-row [class.warn]="row.warn" *matRowDef="let row; columns: pointedItemsColumns;"></tr>

                    </table>

                    <ng-container *ngIf="!loaderService.loading && !pointedItemsDataSource?.data.length"
                        class="m-5 p-5">
                        <p class="text-center text-muted m-5"><em>{{'messages.noResults' | translate}}</em></p>
                    </ng-container>

                </div>
            </mat-tab>

            <mat-tab label="{{'notPointed' | translate}}">
                <div class="not-pointed-items">
                    <div class="not-pointed-items">
                        <table mat-table [dataSource]="notPointedItemsDataSource" class="w-100">

                            <ng-container matColumnDef="itemNumber">
                                <th mat-header-cell *matHeaderCellDef>{{'columns.code' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.itemNumber}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef>{{'columns.description' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="d-flex align-items-center">
                                        {{element.description}}
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="location">
                                <th mat-header-cell *matHeaderCellDef>{{'columns.location' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="d-flex align-items-center">
                                        {{element.location}}{{element.locationSuffix}}
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="lot">
                                <th mat-header-cell *matHeaderCellDef>{{'columns.batch' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="d-flex align-items-center">
                                        <span class="flex-grow-1">{{element.lot}}</span>
                                    </div>
                                </td>
                            </ng-container>
    
                            <ng-container matColumnDef="countedQuantity">
                                <th mat-header-cell *matHeaderCellDef>{{'columns.countedQuantity' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="d-flex align-items-center">
                                        <span class="flex-grow-1">{{element.countedQuantity}}</span>
                                        <button *ngIf="!data.sent" mat-icon-button color="primary"
                                            (click)="editNotPointedItem(element)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button *ngIf="!data.sent && (data.statusId === '20' || data.statusId === '30')"
                                            mat-icon-button color="warn" (click)="deleteNotPointedItem(element)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        <mat-icon *ngIf="data.sent" class="ml-2 text-muted lock">lock</mat-icon>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="notPointedItemsColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: notPointedItemsColumns;"></tr>

                        </table>

                        <ng-container *ngIf="!loaderService.loading && !notPointedItemsDataSource?.data.length"
                            class="m-5 p-5">
                            <p class="text-center text-muted m-5"><em>{{'messages.noResults' | translate}}</em></p>
                        </ng-container>

                        <div class="spacer"></div>
                    </div>

                    <button *ngIf="!data.sent" type="button" mat-fab (click)="addNotPointedItem()">
                        <mat-icon>add</mat-icon>
                    </button>

                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button mat-flat-button type="button" (click)="close()" color="warn">{{'buttons.close' | translate}}</button>
    </div>

</div>

<app-loader></app-loader>