<mat-dialog-content>
    <div class="image-container" *ngIf="showCamera">
        <div class="camera">
            <video id="video"></video>
            <button *ngIf="showCameraButtons" mat-fab class="light" (click)="toggleLight()">
                <mat-icon *ngIf="torch">flash_on</mat-icon>
                <mat-icon *ngIf="!torch">flash_off</mat-icon>
            </button>            
        </div>
        <div class="buttons" *ngIf="showCameraButtons">
            <button mat-fab class="take" (click)="takePhoto()">
                <mat-icon>camera_alt</mat-icon>
            </button>
        </div>
    </div>
    <div class="image-container" *ngIf="hasPhoto">
        <img id="image">        
        <div class="buttons">
            <button mat-fab class="delete" color="warn" (click)="toggleCamera()">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-fab class="ok" color="primary" (click)="confirm()">
                <mat-icon>done</mat-icon>
            </button>
        </div>
    </div>    
</mat-dialog-content>
<div mat-dialog-actions class="justify-content-end">
    <button mat-flat-button type="button" (click)="close(false)" color="warn">
        {{'buttons.close' | translate}}
    </button>
</div>