import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LocationSearchDialogComponent } from '../location-search-dialog/location-search-dialog.component';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-branch-search-dialog',
  templateUrl: './branch-search-dialog.component.html',
  styleUrls: ['./branch-search-dialog.component.scss']
})
export class BranchSearchDialogComponent implements OnInit {

  filter: string;
  displayedColumns: string[] = ['branch'];
  dataSource: MatTableDataSource<string>;
  filterChange: Subject<string> = new Subject<string>();
  selectedBranch: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<LocationSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private dataService: DataService) {
    this.filterChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe((value: string) => {
        this.dataSource.filter = value ? value.trim().toLowerCase() : '';
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      });
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    setTimeout(() => {
      this.loadData();
    }, 100);
  }

  txtFilterChange(value: string) {
    this.filterChange.next(value);
  }

  clearFilter() {
    this.filter = '';
    this.filterChange.next(this.filter);
  }

  loadData() {
    this.dataService.getBranches().subscribe((response) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = (data: string, filter: string) => !filter || data.toLowerCase().startsWith(filter.toLowerCase());
    });
  }


  cancel(): void {
    this.dialogRef.close();
  }

  select(row: string) {
    this.selectedBranch = row;
  }

}
