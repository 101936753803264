import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlider } from '@angular/material/slider';
import { WebcamComponent, WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-photo-dialog',
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.scss']
})
export class PhotoDialogComponent implements OnInit {

  @ViewChild('camera', { static: false }) camera: WebcamComponent;
  @ViewChild('slider', { static: false }) slider: MatSlider;

  private track: any;
  public capabilities: any;
  public focusDistance = 1;
  public focusDistanceMin = 0.15;
  public focusDistanceMax = 4;
  public torch = false;
  public showCamera = true;
  public allowCameraSwitch = true;
  public videoOptions: MediaTrackConstraints = {
    width: 500,
    height: 500,
    facingMode: {
      ideal: environment.facingMode
    }
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public cameraImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();

  showCameraButtons = false;

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }


  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleImage(cameraImage: WebcamImage): void {
    // console.log(cameraImage);
    this.cameraImage = cameraImage;
    this.toggleCamera();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public cameraWasSwitched(deviceId: string): void {
    this.showCameraButtons = true;
    const supports = navigator.mediaDevices.getSupportedConstraints();
    // console.log("supports = ", JSON.parse(JSON.stringify(supports)));
    const media = document.querySelector('video').srcObject as MediaStream;
    // console.log(media);
    this.track = media.getVideoTracks()[0] as any;
    this.capabilities = this.track.getCapabilities();
    // console.log("capabilities == " , JSON.parse(JSON.stringify(capabilities)));
    let settings = this.track.getSettings();
    // console.log("settings == ", JSON.parse(JSON.stringify(settings)));

    if ('focusDistance' in this.capabilities) {
      this.focusDistanceMin = this.capabilities.focusDistance.min;
      this.focusDistanceMax = this.capabilities.focusDistance.max;
      this.focusDistance = this.focusDistanceMax / 2;
      setTimeout(() => {
        this.track.applyConstraints({
          advanced:
            [{
              focusMode: "manual",
              focusDistance: this.focusDistance
            }]
        });
      }, 100);
    }

    // setTimeout(() => {
    //   settings = this.track.getSettings();
    //   console.log("settings == ", JSON.parse(JSON.stringify(settings)));
    // }, 200);
  }

  public deleteImage(): void {
    this.cameraImage = null;
    this.toggleCamera();
  }

  public focusDistanceChanged(): void {
    if ('focusDistance' in this.capabilities) {
      this.track.applyConstraints({ advanced: [{ focusMode: "manual", focusDistance: this.focusDistance }] });
    }
  }

  public torchChanged(): void {    
      this.track.applyConstraints({ advanced: [{ torch: this.torch }] });    
  }

  private toggleCamera() {
    this.showCamera = !this.showCamera;
  }

}
