import { Component } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Inventory Count';

  isIframe = false;
  loggedIn = false;

  constructor(private broadcastService: BroadcastService, private authService: MsalService, public translate: TranslateService) {
    translate.addLangs(['en-US', 'pt-BR']);    
    const language = navigator.language.split('-')[0] === 'pt' ? 'pt-BR' : 'en-US';
    translate.setDefaultLang(language);
    this.translate.use(language);
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.subscribe('msal:loginSuccess', (response) => {
      // console.log(response);
      // this.checkoutAccount();
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
    });
  }
}