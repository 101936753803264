import { DataService } from './../../services/data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { CycleCountListItem } from 'src/app/models/cycle-count-list-item.model';
import { AuthService } from 'src/app/services/auth.service';
import { CycleCountItemsComponent } from '../cycle-count-items/cycle-count-items.component';
import { ExportCycleCountDialogComponent } from '../export-cycle-count-dialog/export-cycle-count-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-archived-cycle-counts',
  templateUrl: './archived-cycle-counts.component.html',
  styleUrls: ['./archived-cycle-counts.component.scss']
})
export class ArchivedCycleCountsComponent implements OnInit {

  filter: string;
  displayedColumns: string[] = ['id', 'description', 'status'];
  dataSource: MatTableDataSource<CycleCountListItem>;
  subscription: Subscription;
  filterChange: Subject<string> = new Subject<string>();
  cycleCountId: number;
  selectedRow: CycleCountListItem;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private _dataService: DataService,
    private _authService: AuthService,    
    public dialog: MatDialog) {
    this.filterChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe((value: string) => {
        this.dataSource.filter = value ? value.trim().toLowerCase() : '';
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
        this.selectedRow = null;
      });
  }

  ngOnInit() {  
    this.loadData();
  }
  
  txtFilterChange(value: string) {
    this.filterChange.next(value);
  }

  clearFilter() {
    this.filter = '';
    this.filterChange.next(this.filter);
  }

  loadData() {
    this.subscription = this._dataService.getArchivedCycleCounts(this._authService.user.id).subscribe((response) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openCycleCount(cycleCount: CycleCountListItem) {
    const dialogRef = this.dialog.open(CycleCountItemsComponent, {
      data: cycleCount,
      autoFocus: false,
      width: '100vw',
      height: '650px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        setTimeout(() => {
          this.loadData();
        }, 100);
      }
    });
  }

  

  select(row: CycleCountListItem) {
    this.selectedRow = row;
  }

  resend() {
    this.dialog.open(ExportCycleCountDialogComponent, {
      width: '75%',
      data: this.selectedRow
    });
  }

}
