import { Bull } from "./bull.model";

export class CycleCountCanisterNotPointedItem {
    id: string;
    countedQuantity: number;
    itemNumber: string;
    desctiption: string;
    location: string;
    locationSuffix: string;
    lot: string;
    bull: Bull;
    branchPlant: string;
    cycleNumber: number;
}