<div class="container-fluid pt-3">
    <div class="row">
        <div class="col">
            <h3 class="my-2">{{'menu.lotsToCount' | translate}}</h3>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <form>
                <mat-form-field class="w-100">
                    <mat-label>{{'messages.typeLot' | translate}}</mat-label>
                    <input matInput type="text" class="text-uppercase" [(ngModel)]="filter"
                        (ngModelChange)="txtFilterChange($event)" id="txtFilter" name="txtFilter" #fcFilter="ngModel">
                    <button class="clear-button" mat-button matSuffix mat-icon-button (click)="clearFilter()"
                        *ngIf="filter">
                        <mat-icon class="text-muted">close</mat-icon>
                    </button>
                    <mat-icon class="mr-2 text-muted" matSuffix *ngIf="!filter">search</mat-icon>
                </mat-form-field>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" class="w-100">
                    <!-- Id Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>{{'columns.code' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element.id}}</span>
                        </td>
                    </ng-container>
                    <!-- Description Column -->
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>{{'columns.description' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="d-flex align-items-center">
                                <span class="flex-grow-1">{{element.description}}</span>
                                <button class="py-2 my-1" mat-flat-button type="button" color="primary"
                                    (click)="startCount(element)" [class.recount]="element.isRecount">
                                    <label class="d-block">{{'buttons.start' | translate}}</label>
                                    <label class="d-block">{{(element.isRecount ? 'buttons.recounting' : 'buttons.counting') | translate}}</label>                                    
                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>
                <ng-container *ngIf="!dataSource?.filteredData.length" class="m-5 p-5">
                    <p class="text-center text-muted m-5"><em>{{'messages.noResults' | translate}}</em></p>
                </ng-container>
            </div>
            <mat-paginator [pageSize]="50"></mat-paginator>
        </div>
    </div>
</div>