import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bull } from '../models/bull.model';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { CycleCountListItem } from '../models/cycle-count-list-item.model';
import { CycleCountItems } from '../models/cycle-count-items.model';
import { CycleCountPointedItem } from '../models/cycle-count-pointed-item.model';
import { CycleCountNotPointedItem } from '../models/cycle-count-not-pointed-item.model';
import { ExportCycleCountRequest } from './../models/export-cycle-count-request';
import { StartCycleCountRequest } from './../models/start-cylce-count-request.model';
import { TranslateService } from '@ngx-translate/core';
import { CycleCountCanister } from '../models/cycle-count-canister.model';
import { CycleCountCanisterItems } from '../models/cycle-count-canister-items.model';
import { CycleCountCanisterPointedItem } from '../models/cycle-count-canister-pointed-item.model';
import { CycleCountCanisterNotPointedItem } from '../models/cycle-count-canister-not-pointed-item.model';
import { CycleCountItemToRecount } from '../models/cycle-count-item-to-recount.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private _apiUrl = environment.apiUrl;  

  constructor(private _http: HttpClient, private _translateService: TranslateService) { }

  getBulls(): Observable<Bull[]> {
    return this._http.get<Bull[]>(`${this._apiUrl}/bulls/`).pipe(retry(0), catchError(this.handleError));
  }

  // getLocations(): Observable<BankLocation[]> {
  //   return this._http.get<BankLocation[]>(`${this.apiUrl}/locations`).pipe(retry(3), catchError(this.handleError));
  // }

  getLocationsByCycle(id: number): Observable<string[]> {
    return this._http.get<string[]>(`${this._apiUrl}/locations/bycycle/${id}`).pipe(retry(3), catchError(this.handleError));
  }

  getBranches(): Observable<string[]> {
    return this._http.get<string[]>(`${this._apiUrl}/branches`).pipe(retry(3), catchError(this.handleError));
  }

  getBranch(itemNumber: number, location: string, lot: string): Observable<string> {
    return this._http.get(`${this._apiUrl}/branches/${itemNumber}/${location}/${lot}`,
      { responseType: 'text' }
    ).pipe(retry(3), catchError(this.handleError));
  }

  getCycleCountsToStart(): Observable<CycleCountListItem[]> {
    return this._http.get<CycleCountListItem[]>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/tostart`).pipe(retry(3), catchError(this.handleError));
  }

  getStartedCycleCounts(user: string): Observable<CycleCountListItem[]> {
    return this._http.get<CycleCountListItem[]>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/startedbyuser/${user}`).pipe(retry(3), catchError(this.handleError));
  }

  getExportedCycleCounts(user: string): Observable<CycleCountListItem[]> {
    return this._http.get<CycleCountListItem[]>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/exportedbyuser/${user}`).pipe(retry(3), catchError(this.handleError));
  }

  getArchivedCycleCounts(user: string): Observable<CycleCountListItem[]> {
    return this._http.get<CycleCountListItem[]>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/archivedbyuser/${user}`).pipe(retry(3), catchError(this.handleError));
  }

  getItems(id: number): Observable<CycleCountItems> {
    return this._http.get<CycleCountItems>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/items/${id}`).pipe(retry(0), catchError(this.handleError));
  }

  startCycleCount(item: StartCycleCountRequest): Observable<any> {
    return this._http.put<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/start`, item, this.options).pipe(retry(3), catchError(this.handleError));
  }

  updatePointedItem(item: CycleCountPointedItem): Observable<CycleCountPointedItem> {
    return this._http.put<CycleCountPointedItem>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/updatepointeditem`, item, this.options).pipe(retry(3), catchError(this.handleError));
  }

  addNotPointedItem(item: CycleCountNotPointedItem): Observable<any> {    
    return this._http.post<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/addnotpointeditem`, item, this.options).pipe(catchError(this.handleError));
  }

  updateNotPointedItem(item: CycleCountNotPointedItem): Observable<any> {
    return this._http.put<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/updatenotpointeditem`, item, this.options).pipe(retry(3), catchError(this.handleError));
  }

  deleteNotPointedItem(id: string): Observable<any> {
    return this._http.delete<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/deletenotpointeditem/${id}`).pipe(retry(3), catchError(this.handleError));
  }

  addCanisterNotPointedItem(item: CycleCountCanisterNotPointedItem): Observable<any> {    
    return this._http
      .post<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/addcanisternotpointeditem`, item, this.options)
      .pipe(catchError(this.handleError));
  }

  deleteCanisterNotPointedItem(id: string): Observable<any> {
    return this._http
      .delete<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/deletecanisternotpointeditem/${id}`)
      .pipe(retry(3), catchError(this.handleError));
  }

  updateCanisterNotPointedItem(item: CycleCountCanisterNotPointedItem): Observable<any> {
    return this._http
      .put<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/updatecanisternotpointeditem`, item, this.options)
      .pipe(retry(3), catchError(this.handleError));
  }

  updateCanisterPointedItem(item: CycleCountCanisterPointedItem): Observable<CycleCountCanisterPointedItem> {
    return this._http
      .put<CycleCountCanisterPointedItem>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/updatecanisterpointeditem`, item, this.options)
      .pipe(retry(3), catchError(this.handleError));
  }

  resetCycleCount(id: number): Observable<any> {
    return this._http.put<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/resetcount/${id}`, null, this.options).pipe(retry(3), catchError(this.handleError));
  }

  // recount(id: number): Observable<any> {
  //   return this._http.put<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/recount/${id}`, null, this.options).pipe(retry(3), catchError(this.handleError));
  // }

  recountItems(cycleId: number, itemsToRecount: CycleCountItemToRecount[]): Observable<any> {
    return this._http
      .put<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/recount/${cycleId}`, itemsToRecount, this.options)
      .pipe(retry(3), catchError(this.handleError));
  }

  getItemsToRecount(cycleId: number): Observable<CycleCountItemToRecount[]> {
    return this._http
            .get<CycleCountItemToRecount[]>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/recount/items/${cycleId}`)
            .pipe(retry(3), catchError(this.handleError));
  }

  exportCycleCount(exportOptions: ExportCycleCountRequest, resend: boolean): Observable<any> {
    return this._http.post(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/export/${resend}`, JSON.stringify(exportOptions), this.options).pipe(retry(3), catchError(this.handleError));
  }

  cycleCountIsValid(id: number): Observable<boolean> {
    return this._http.get<boolean>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/isvalid/${id}`).pipe(retry(3), catchError(this.handleError));
  }

  syncCycles(): Observable<any> {
    return this._http.get<any>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/sync`).pipe(retry(3), catchError(this.handleError));
  }

  getCanisters(id: number): Observable<CycleCountCanister[]> {
    return this._http
            .get<CycleCountCanister[]>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/canisters/${id}`)
            .pipe(retry(0), catchError(this.handleError));
  }

  getCanistersItems(cycleId: number, location: string): Observable<CycleCountCanisterItems> {
    return this._http
            .get<CycleCountCanisterItems>(`${this._apiUrl}/${this._translateService.currentLang}/cyclecounts/canisters/items/${cycleId}/${location}`)
            .pipe(retry(0), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.message}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.error(error);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

}
