<mat-dialog-content>
    <div class="image-container" *ngIf="showCamera">
        <div class="d-flex flex-column">
            <webcam #camera [height]="500" [width]="500" [trigger]="triggerObservable" [videoOptions]="videoOptions"
                [imageType]='image/jpeg' [allowCameraSwitch]="allowCameraSwitch" [imageQuality]="1"
                (imageCapture)="handleImage($event)" (cameraSwitched)="cameraWasSwitched($event)" 
                (initError)="handleInitError($event)" >
            </webcam>
            <div class="d-flex flex-row">
                <div class="d-flex flex-column flex-grow-1 justify-content-center mr-3">
                    <small>{{'focus' | translate}}</small>
                    <mat-slider #slider step="0.001" [(ngModel)]="focusDistance" (change)="focusDistanceChanged()"
                        [min]="focusDistanceMin" [max]="focusDistanceMax">
                    </mat-slider>
                </div>
                <div class="d-flex flex-column flex-grow-1 justify-content-center ml-3">
                    <mat-slide-toggle [(ngModel)]="torch" (change)="torchChanged()">{{'light' | translate}}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
        <div class="buttons" *ngIf="showCameraButtons">
            <button mat-fab class="take" (click)="triggerSnapshot()">
                <mat-icon>camera_alt</mat-icon>
            </button>
        </div>
    </div>
    <div class="image-container" *ngIf="!showCamera && cameraImage">
        <img [src]="cameraImage.imageAsDataUrl" />
        <div class="buttons">
            <button mat-fab class="delete" color="warn" (click)="deleteImage()">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-fab class="ok" color="primary" [mat-dialog-close]="cameraImage">
                <mat-icon>done</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-content>
<div mat-dialog-actions class="justify-content-end">
    <button mat-flat-button type="button" [mat-dialog-close]="false" color="warn">
        {{'buttons.close' | translate}}
    </button>
</div>