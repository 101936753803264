import { ErrorDialogComponent } from './../../error-dialog/error-dialog.component';
import { DataService } from './../../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { ExportCycleCountDialogComponent } from '../export-cycle-count-dialog/export-cycle-count-dialog.component';
import { CycleCountListItem } from './../../models/cycle-count-list-item.model';
import { ConfirmDialogComponent } from './../../confirm-dialog/confirm-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CycleCountItemsComponent } from '../cycle-count-items/cycle-count-items.component'
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CountTypeDialogComponent } from 'src/app/count-type-dialog/count-type-dialog.component';

@Component({
  selector: 'app-started-cycle-counts',
  templateUrl: './started-cycle-counts.component.html',
  styleUrls: ['./started-cycle-counts.component.scss']
})
export class StartedCycleCountsComponent implements OnInit {

  filter: string;
  displayedColumns: string[] = ['id', 'description'];
  dataSource: MatTableDataSource<CycleCountListItem>;
  subscription: Subscription;
  filterChange: Subject<string> = new Subject<string>();
  cycleId: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private _dataService: DataService,
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    public dialog: MatDialog) {
    this.filterChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe((value: string) => {
        this.dataSource.filter = value ? value.trim().toLowerCase() : '';
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      });
  }

  ngOnInit() {
    this.initializeParams();
    this.loadData(true);
  }

  initializeParams() {
    const paramMap = this._route.snapshot.paramMap;
    this.cycleId = +paramMap.get('cycleId');
  }

  txtFilterChange(value: string) {
    this.filterChange.next(value);
  }

  clearFilter() {
    this.filter = '';
    this.filterChange.next(this.filter);
  }

  loadData(showItems: boolean) {
    this.subscription = this._dataService.getStartedCycleCounts(this._authService.user.id).subscribe((response) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if (showItems && this.cycleId) {
        const cycle = this.dataSource.data.filter((c) => c.id === this.cycleId);
        if (cycle && cycle.length) {
          this.openCycleCount(cycle[0]);
        }
      }
    });
  }

  openCycleCount(cycleCount: CycleCountListItem) {
    if (cycleCount.isRecount) {
      const dialogRef = this.dialog.open(CycleCountItemsComponent, {
        data: cycleCount,
        autoFocus: false,
        width: '100vw',
        // height: '750px'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          setTimeout(() => {
            this.loadData(false);
          }, 100);
        }
      });
    } else {
      this.openCountTypeDialog(cycleCount);
    }
  }

  resetCycleCount(item: CycleCountListItem) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: this._translateService.instant('messages.giveBack', { itemId: item.id, itemDescription: item.description })
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._dataService.resetCycleCount(item.id).subscribe(() => {
          this.loadData(false);
        }, (error) => {
          console.log(error);
        });
      }
    });
  }

  export(item: CycleCountListItem) {
    if (item.isRecount) {
      this.sendToExport(item);
    } else {
      this._dataService.cycleCountIsValid(item.id).subscribe((response) => {
        if (response) {
          this.sendToExport(item);
        } else {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message: this._translateService.instant('messages.requiredCycleCountData')
            }
          });
        }
      }, error => {
        console.log(error);
      });
    }
  }

  private sendToExport(item: CycleCountListItem) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: this._translateService.instant('messages.confirmSubmit', { itemId: item.id, itemDescription: item.description })
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        setTimeout(() => {
          const dialogRef = this.dialog.open(ExportCycleCountDialogComponent, {
            width: '75%',
            data: item
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.loadData(false);
            }
          });
        }, 100);
      }
    });
  }

  openCountTypeDialog(cycleCount: CycleCountListItem): void {
    const dialogRef = this.dialog.open(CountTypeDialogComponent, {
      data: cycleCount,
      height: '200px',
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Selected count type:', result);
    });
  }
}
