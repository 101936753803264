<form #form="ngForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>{{'code' | translate}}</mat-label>
            <input name="txtItemNumber" matInput disabled [(ngModel)]="data.item.itemNumber">
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>{{'description' | translate}}</mat-label>
            <input name="txtDescription" matInput disabled [(ngModel)]="data.item.description">
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>{{'location' | translate}}</mat-label>
            <input name="txtLocation" matInput disabled [(ngModel)]="data.item.location">
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>{{'batch' | translate}}</mat-label>
            <input name="txtLot" matInput disabled [(ngModel)]="data.item.lot">
        </mat-form-field>
        <mat-form-field class="w-100" [hideRequiredMarker]="true">
            <mat-label>{{'quantity' | translate}}</mat-label>
            <input name="txtQuantity" type="number" min="0" matInput required [(ngModel)]="quantity" #fcQuantity
                cdkFocusInitial>
            <mat-error>
                {{quantity < 0 ? translateService.instant('messages.minValue') :
                    translateService.instant('messages.requiredField')}} </mat-error>
        </mat-form-field>
        <div class="image">
            <input #inputCamera id="cameraFileInput" type="file" accept="image/*" capture="environment"
                (change)="imageChange($event)" />
            <mat-label>Imagem</mat-label>
            <div class="thumb" *ngIf="imageData || (!data.isRecount && data.item.countImageUrl) || (data.isRecount && data.item.recountImageUrl)">
                <img [src]="thumbSrc" (click)="viewImage()">
                <button mat-mini-fab color="warn" class="ml-2" (click)="deleteImage()">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <button mat-flat-button type="button" color="accent" class="photo my-2" (click)="openCamera()">
                <mat-icon>camera_alt</mat-icon>
                <span class="ml-2">{{'buttons.photo' | translate}}</span>
            </button>
        </div>
        <small *ngIf="error" class="text-danger d-block">{{'messages.operationError' | translate}}</small>
    </div>
    <div mat-dialog-actions class="justify-content-end m-0">
        <button mat-flat-button type="button" (click)="cancel()" class="mr-4" color="warn">{{'buttons.cancel' |
            translate}}
        </button>
        <button mat-flat-button type="submit" color="primary">{{'buttons.ok' | translate}}</button>
    </div>
</form>
<app-image-viewer #imageViewer [source]="thumbSrc"></app-image-viewer>
<app-loader></app-loader>