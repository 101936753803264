import { AuthService } from './../services/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  @ViewChild(MatSidenav, { static: true }) sideNav: MatSidenav;

  constructor(private _dataService: DataService,
    private _translateService: TranslateService,
    private _router: Router, public authService: AuthService, public dialog: MatDialog) { }

  ngOnInit() {
  }

  block() {
    this.sideNav.close();
    this.authService.block();
  }

  logout() {
    this.authService.logout();
  }

  sync() {
    this._dataService.syncCycles().subscribe(() => {
      this._router.navigateByUrl('/');
    }, (error: HttpErrorResponse) => {
      this.sideNav.close();
      console.log(error);
      if (error.status === 403) {
        this.dialog.open(InfoDialogComponent, {
          data: {
            message: this._translateService.instant('messages.syncError')
          }
        });
      }
    });
  }

}
