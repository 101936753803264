<div class="container-fluid">

    <h1 mat-dialog-title>
        {{'lot' | translate}} {{data.id}} - {{data.description}}
    </h1>

    <div mat-dialog-content>
        <div class="items">
            <table 
                mat-table 
                [dataSource]="dataSource" 
                class="w-100">

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>{{'columns.qty' | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.countQuantity}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="itemNumber">
                    <th mat-header-cell *matHeaderCellDef>{{'columns.code' | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.itemNumber}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>{{'columns.description' | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex align-items-center">
                            <span>{{element.description}}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>{{'columns.location' | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex align-items-center">
                            <span>{{element.location}}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lot">
                    <th mat-header-cell *matHeaderCellDef>{{'columns.batch' | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex align-items-center">
                            <span class="flex-grow-1">
                                {{element.lot}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row [class.warn]="row.warn" *matRowDef="let row; columns: columns;"></tr>

            </table>

            <ng-container *ngIf="!loaderService.loading && !dataSource?.data.length" class="m-5 p-5">
                <p class="text-center text-muted m-5"><em>{{'messages.noResults' | translate}}</em></p>
            </ng-container>

        </div>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button mat-flat-button type="button" (click)="close()" color="warn">
            {{'buttons.close' | translate}}
        </button>
        <button mat-flat-button type="button" (click)="recountItems()" color="primary" [disabled]="!hasSelectedItems()">
            {{'buttons.ok' | translate}}
        </button>
    </div>

</div>

<app-loader></app-loader>