<main class="container-fluid">
    <div class="row align-items-center justify-content-center">
        <div class="col-12 col-md-6 text-center text-white d-flex flex-column align-items-center h-100">
            <h1 class="mt-auto">Inventory Count</h1>
            <ng-container *ngIf="videoStart; else start">
                <!-- Video -->
                <div class="video-row">
                    <video class="video" id="video" #video autoplay playsinline></video>
                </div>
                <!-- Canvas -->
                <div style="display:none">
                    <canvas class="video" id="canvas" #canvas></canvas>
                </div>
                <small class="my-2">{{'messages.pointQRCode' | translate}}</small>
                <p *ngIf="errorMessage">{{errorMessage}}</p>
                <button class="px-5 py-3" mat-stroked-button (click)="toggleVideoMedia()">{{'buttons.cancel' | translate}}</button>
            </ng-container>
            <ng-template #start>
                <div class="my-auto">
                    <p class="lock m-0">
                        <mat-icon inline="true">lock</mat-icon>
                    </p>
                    <button class="px-5 py-3" mat-stroked-button (click)="toggleVideoMedia()">{{'buttons.unlock' | translate}}</button>
                </div>
            </ng-template>
            <img class="img-fluid d-block mt-auto mb-2 logo" src="/assets/images/urus-white-logo.png">
        </div>
    </div>
</main>