<h1 mat-dialog-title>{{'submit' | translate}} {{data.id}}</h1>
<form #form="ngForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>{{'recipient' | translate}}</mat-label>
            <input matInput type="email" [(ngModel)]="to" id="txtTo" name="txtTo"
                #fcTo="ngModel" required pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            <button class="clear-button" mat-button matSuffix mat-icon-button (click)="to = ''" *ngIf="to">
                <mat-icon class="text-muted">close</mat-icon>
            </button>
            <mat-icon class="mr-2 text-muted" matSuffix *ngIf="!to">email</mat-icon>
            <mat-error *ngIf="fcTo.touched && fcTo.invalid">
                {{'messages.invalidEmail' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100 mt-3">
            <mat-label>{{'copy' | translate}}</mat-label>
            <input matInput type="email" [(ngModel)]="cc" id="txtCc" name="txtCc" #fcCc="ngModel">
            <button class="clear-button" mat-button matSuffix mat-icon-button (click)="cc = ''" *ngIf="cc">
                <mat-icon class="text-muted">close</mat-icon>
            </button>
            <mat-icon class="mr-2 text-muted" matSuffix *ngIf="!cc">email</mat-icon>
            <mat-error *ngIf="fcCc.touched && fcCc.invalid">
                {{'messages.invalidEmail' | translate}}
            </mat-error>
        </mat-form-field>
        <small class="text-muted">{{'messages.separateAddresses' | translate}}</small>
        <small *ngIf="error" class="text-danger d-block">{{'messages.operationError' | translate}}</small>
    </div>
    <div mat-dialog-actions class="justify-content-end m-0">
        <button mat-flat-button (click)="cancel()" class="mr-2" color="warn">{{'buttons.cancel' | translate}}</button>
        <button mat-flat-button type="submit" color="primary">{{'buttons.ok' | translate}}</button>
    </div>
</form>
<app-loader></app-loader>