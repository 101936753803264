<div class="container-fluid"></div>

    <h1 mat-dialog-title>
        {{'lot' | translate}} {{data.id}} - {{data.description}}
    </h1>
            
    <div class="row">
        <div class="col">
            <div class="table-container">
                <table mat-table [dataSource]="canisters" class="w-100">

                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'columns.location' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="d-flex align-items-center">
                                <span class="flex-grow-1">
                                    {{element.location}}
                                </span>
                                <button *ngIf="element.counted" mat-icon-button type="button" color="primary" class="mr-2"
                                    (click)="openCountedItems(element)">
                                    <mat-icon>remove_red_eye</mat-icon>
                                </button>
                                <button *ngIf="!element.counted" mat-icon-button type="button" color="primary" class="mr-2"
                                    (click)="countCanister(element)">
                                    <mat-icon>send</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                    <tr mat-row [class.warn]="row.warn" *matRowDef="let row; columns: columns;"></tr>

                </table>

                <ng-container *ngIf="!loaderService.loading && !canisters?.data.length" class="m-5 p-5">
                    <p class="text-center text-muted m-5"><em>{{'messages.noResults' | translate}}</em></p>
                </ng-container>

            </div>
        </div>
    </div>
    
    <div mat-dialog-actions class="justify-content-end">
        <button mat-flat-button type="button" (click)="close()" color="warn">{{'buttons.close' | translate}}</button>
    </div>

<app-image-viewer #imageViewer [source]="thumbSrc"></app-image-viewer>
<app-loader></app-loader>