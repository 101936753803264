import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

declare const ImageCapture: any;

@Component({
  selector: 'app-photo-dialog-v2',
  templateUrl: './photo-dialog-v2.component.html',
  styleUrls: ['./photo-dialog-v2.component.scss']
})
export class PhotoDialogV2Component implements OnInit, AfterViewInit {

  // video: any;
  // canvas: any;

  hasPhoto = false;
  stream: MediaStream;
  track: any;
  capabilities: any;
  imageCapture: any;
  showCamera = false;
  showCameraButtons = false;
  public focusDistance = 1;
  focusDistanceMin = 0.15;
  focusDistanceMax = 4;
  showTorchControl = false;
  torch = false;
  photoBase64: string;

  videoOptions: MediaTrackConstraints = {
    //width: 600,
    // height: 500,
    facingMode: {
      ideal: environment.facingMode
    }
  };

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.toggleCamera();
    }, 50);
  }

  gotMedia(mediaStream: MediaStream): void {
    this.stream = mediaStream;
    const video = document.querySelector('video');
    this.track = mediaStream.getVideoTracks()[0];
    this.capabilities = this.track.getCapabilities()

    if ('focusDistance' in this.capabilities) {
      this.focusDistanceMin = this.capabilities.focusDistance.min;
      this.focusDistanceMax = this.capabilities.focusDistance.max;
      this.focusDistance = this.focusDistanceMin + ((this.focusDistanceMax - this.focusDistanceMin) / 2);
      setTimeout(() => {
        this.track.applyConstraints({
          advanced:
            [{
              focusMode: "manual",
              focusDistance: this.focusDistance
            }]
        });
      }, 50);
    }

    if ('torch' in this.capabilities) {
      this.showTorchControl = true;
    }

    this.imageCapture = new ImageCapture(this.track);
    // console.log(imageCapture);
    video.srcObject = mediaStream;
    video.play();
    this.showCameraButtons = true;
  }

  takePhoto(): void {
    this.imageCapture.takePhoto()
      .then((blob: Blob) => {
        this.hasPhoto = true;
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.photoBase64 = reader.result.toString();
        }
        setTimeout(() => {
          const img = document.querySelector('img');
          img.src = URL.createObjectURL(blob);
          // img.onload = () => { URL.revokeObjectURL(img.src); }
          this.toggleCamera();

        }, 50);
      })
      .catch((error: any) => {
        console.error('takePhoto() error:', error);
      });
  }

  confirm(): void {
    this.close(this.photoBase64);
  }

  close(value: string | boolean): void {
    this.stopCamera();
    this.dialogRef.close(value);
  }

  public toggleLight(): void {
    this.torch = !this.torch;
    this.torchChanged();
  }

  private torchChanged(): void {
    this.track.applyConstraints({ advanced: [{ torch: this.torch }] });
  }

  private toggleCamera() {
    this.showCamera = !this.showCamera;
    if (this.showCamera) {
      navigator.mediaDevices.getUserMedia({ video: this.videoOptions })
        .then((response) => this.gotMedia(response))
        .catch(error => console.error('getUserMedia() error:', error));
      this.hasPhoto = false;
    } else {
      this.stopCamera();
    }
  }

  private stopCamera(): void {
    this.torch = false;
    this.showTorchControl = false;
    this.torchChanged();
    setTimeout(() => {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }, 50);
  }
}
