import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CycleCountCanisterInsertTransactionLog } from '../models/cycle-count-canister-insert-transaction-log.model';

@Injectable({
  providedIn: 'root'
})
export class AIIntegrationService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private aiAppIntegrationUrl = 'api/v1/aiintegration/app';
  private aiIntegrationUrl = 'api/v1/aiintegration';

  constructor(private http: HttpClient) {}

  getOpenedTransactionLog(cycleId: number, canisterLocation: string): Observable<CycleCountCanisterInsertTransactionLog> {
    return this.http
      .get<CycleCountCanisterInsertTransactionLog>(`${this.aiIntegrationUrl}/log/${cycleId}/${canisterLocation}`)
      .pipe(catchError(this.handleError));
  }

  insertTransactionLog(transactionLog: CycleCountCanisterInsertTransactionLog): Observable<any> {
    return this.http
      .post<any>(`${this.aiAppIntegrationUrl}`, transactionLog, this.options)
      .pipe(catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.message}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.error(error);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
