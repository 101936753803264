import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Bull } from '../models/bull.model';
import { Subscription, Subject } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from '../services/data.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-bull-search-dialog',
  templateUrl: './bull-search-dialog.component.html',
  styleUrls: ['./bull-search-dialog.component.scss']
})
export class BullSearchDialogComponent implements OnInit {

  filter: string;
  displayedColumns: string[] = ['code', 'name'];
  dataSource: MatTableDataSource<Bull>;  
  subscription: Subscription;
  filterChange: Subject<string> = new Subject<string>();
  selectedBull: Bull

  @ViewChild(MatSort, { static: true }) table: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;  
 
  ngOnInit() {
    setTimeout(() => {
      this.loadData();  
    }, 100);        
  }

  txtFilterChange(value: string) {        
    this.filterChange.next(value);
  }

  clearFilter() {
    this.filter= '';
    this.filterChange.next(this.filter);
  }

  loadData() {
    this.subscription = this.dataService.getBulls().subscribe((response) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.table;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  constructor(
    public dialogRef: MatDialogRef<BullSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private dataService: DataService) {   
      this.filterChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe((value: string) => {        
        this.dataSource.filter = value ? value.trim().toLowerCase() : '';
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      });
      dialogRef.disableClose = true;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  select(row: Bull) {
    this.selectedBull = row;
  }

}
