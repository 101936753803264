import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ArchivedCycleCountsComponent } from './cycle-counts/archived-cycle-counts/archived-cycle-counts.component';
import { CycleCountsToStartListComponent } from './cycle-counts/cycle-counts-to-start-list/cycle-counts-to-start-list.component';
import { ExportedCycleCountsComponent } from './cycle-counts/exported-cycle-counts/exported-cycle-counts.component';
import { StartedCycleCountsComponent } from './cycle-counts/started-cycle-counts/started-cycle-counts.component';
import { IndexComponent } from './index/index.component';
import { LockScreen3Component } from './lock-screen3/lock-screen3.component';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';
import { LogoutComponent } from './logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', component: LoginRedirectComponent },
  { path: 'logout', component: LogoutComponent },
  {    
    path: 'logged', component: IndexComponent, canActivate: [MsalGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'lock', component: LockScreen3Component },
      {
        path: '', canActivate: [AuthGuardService], runGuardsAndResolvers: 'always',        
        children: [
          { path: '', redirectTo: 'lotes-a-contar', pathMatch: 'full' },
          { path: 'lotes-a-contar', component: CycleCountsToStartListComponent},
          { path: 'lotes-em-contagem', component: StartedCycleCountsComponent},
          { path: 'lotes-em-contagem/:cycleId', component: StartedCycleCountsComponent},
          { path: 'lotes-enviados', component: ExportedCycleCountsComponent},
          { path: 'lotes-arquivados', component: ArchivedCycleCountsComponent},
          { path: '**', component: NotFoundComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      initialNavigation: isInIframe() ? 'disabled' : undefined,
      onSameUrlNavigation: 'reload' 
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}