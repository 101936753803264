import { InterceptorModule } from './interceptors/interceptor.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { LoaderComponent } from './loader/loader.component';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { IndexComponent } from './index/index.component';
import { BullSearchDialogComponent } from './bull-search-dialog/bull-search-dialog.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { getPaginatorIntl } from 'src/intl/paginator-intl';
import { LockScreen3Component } from './lock-screen3/lock-screen3.component';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CycleCountPointedItemComponent } from './cycle-counts/cycle-count-pointed-item/cycle-count-pointed-item.component';
import { CycleCountNotPointedItemComponent } from './cycle-counts/cycle-count-not-pointed-item/cycle-count-not-pointed-item.component';
import { LocationSearchDialogComponent } from './location-search-dialog/location-search-dialog.component';
import { ExportCycleCountDialogComponent } from './cycle-counts/export-cycle-count-dialog/export-cycle-count-dialog.component';
import { CycleCountsToStartListComponent } from './cycle-counts/cycle-counts-to-start-list/cycle-counts-to-start-list.component';
import { StartedCycleCountsComponent } from './cycle-counts/started-cycle-counts/started-cycle-counts.component';
import { CycleCountItemsComponent } from './cycle-counts/cycle-count-items/cycle-count-items.component';
import { ExportedCycleCountsComponent } from './cycle-counts/exported-cycle-counts/exported-cycle-counts.component';
import { ArchivedCycleCountsComponent } from './cycle-counts/archived-cycle-counts/archived-cycle-counts.component';

import pt from '@angular/common/locales/pt';
import { BranchSearchDialogComponent } from './branch-search-dialog/branch-search-dialog.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';
import { environment } from 'src/environments/environment';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PhotoDialogComponent } from './photo-dialog/photo-dialog.component';

import {WebcamModule} from 'ngx-webcam';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { PhotoDialogV2Component } from './photo-dialog-v2/photo-dialog-v2.component';
import { CountTypeDialogComponent } from './count-type-dialog/count-type-dialog.component';
import { CycleCountCanistersComponent } from './cycle-counts/cycle-count-canisters/cycle-count-canisters.component';
import { CycleCountCanistersDetailsComponent } from './cycle-counts/cycle-count-canisters/cycle-count-canisters-items/cycle-count-canisters-items.component';
import { CycleCountCanisterPointedItemComponent } from './cycle-counts/cycle-count-canisters/cycle-count-canister-pointed-item/cycle-count-canister-pointed-item.component';
import { CycleCountCanisterNotPointedItemComponent } from './cycle-counts/cycle-count-canisters/cycle-count-canister-not-pointed-item/cycle-count-canister-not-pointed-item.component';
import { CycleCountItemsToRecountComponent } from './cycle-counts/cycle-count-items-to-recount/cycle-count-items-to-recount.component';

export const protectedResourceMap: [string, string[]][] = [];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

registerLocaleData(pt, 'pt');


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    IndexComponent,
    BullSearchDialogComponent,
    NotFoundComponent,
    ConfirmDialogComponent,
    ErrorDialogComponent,
    LockScreen3Component,
    LogoutComponent,
    CycleCountPointedItemComponent,
    CycleCountNotPointedItemComponent,
    LocationSearchDialogComponent,
    ExportCycleCountDialogComponent,
    CycleCountsToStartListComponent,
    StartedCycleCountsComponent,
    CycleCountItemsComponent,
    ExportedCycleCountsComponent,
    ArchivedCycleCountsComponent,
    BranchSearchDialogComponent,
    LoginRedirectComponent,
    InfoDialogComponent,
    PhotoDialogComponent,
    ImageViewerComponent,
    PhotoDialogV2Component,
    CountTypeDialogComponent,
    CycleCountCanistersComponent,
    CycleCountCanistersDetailsComponent,
    CycleCountCanisterPointedItemComponent,
    CycleCountCanisterNotPointedItemComponent,
    CycleCountItemsToRecountComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    InterceptorModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTabsModule,
    WebcamModule,
    MatCheckboxModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend]
      }
    }),

    MsalModule.forRoot({
      auth: {
        clientId: environment.clientId,
        authority: environment.authority,
        postLogoutRedirectUri: environment.postLogoutRedirectUri,
        redirectUri: environment.redirectUri
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
      {
        // popUp: !isIE,
        popUp: false,
        consentScopes: environment.consentScopes,
        unprotectedResources: [],
        protectedResourceMap,
        extraQueryParameters: {}
      })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },   
    { provide: LOCALE_ID, useValue: 'pt' },
  ],  
  bootstrap: [AppComponent]
})
export class AppModule { }

// export function httpTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

export function httpLoaderFactory(http: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(http), './assets/i18n/', `.json?version=${new Date().getMilliseconds()}`,);
}