import { DataService } from './../../services/data.service';
import { CycleCountListItem } from '../../models/cycle-count-list-item.model';
import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ExportCycleCountRequest } from '../../models/export-cycle-count-request';
import { AuthService } from '../../services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-export-cycle-count-dialog',
  templateUrl: './export-cycle-count-dialog.component.html',
  styleUrls: ['./export-cycle-count-dialog.component.scss']
})
export class ExportCycleCountDialogComponent {

  to: string;
  cc: string;
  error = false;

  @ViewChild('form', { static: true }) form: NgForm;

  constructor(
    private _authService: AuthService,
    private _dataService: DataService,
    public dialogRef: MatDialogRef<ExportCycleCountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CycleCountListItem,
  ) {
    dialogRef.disableClose = true;    
  }

  onSubmit() {
    this.error = false;
    if (this.validate()) {
      let ccArray = [];
      if (this.cc) {
        ccArray = this.cc.split(';');
      }
      const now = new Date();
      this._dataService.exportCycleCount(
        new ExportCycleCountRequest(
          this.data.id,
          this._authService.user.id,
          `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`,
          this.to,
          ccArray && ccArray.length ? ccArray : null
        ),
        this.data.sent)
        .subscribe(() => {
          this.dialogRef.close(true);
        }, error => {
          console.log(error);
          this.error = true;
        });
    }
  }

  validate() {
    Object.keys(this.form.controls).forEach(field => this.form.controls[field].markAsTouched());
    if (this.form.valid) {
      if (this.cc) {
        const ccArray = this.cc.split(';')
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        for (let i = 0; i < ccArray.length; i++) {
          if (!regexp.test(ccArray[i])) {
            this.form.controls['txtCc'].setErrors({ 'invalid': true });
            break;
          }
          else {
            this.form.controls['txtCc'].setErrors(null);
          }
        }
      }
    }
    return this.form.valid;
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
