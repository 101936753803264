import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import jsQR from "jsqr";
import { AuthService } from '../services/auth.service';
import { LoaderService } from '../services/loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lock-screen3',
  templateUrl: './lock-screen3.component.html',
  styleUrls: ['./lock-screen3.component.scss']
})
export class LockScreen3Component implements OnInit {

  @ViewChild('video', { static: false }) videoElm: ElementRef;
  @ViewChild('canvas', { static: false }) canvasElm: ElementRef;

  scanning = false;
  errorMessage = '';

  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };

  constructor(private changeDetector: ChangeDetectorRef, private authService: AuthService,
    public loaderService: LoaderService, private router: Router) { }

  ngOnInit() { }

  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo()
    }
    // this.videoStart ? this.stopVideo() : this.startVideo()
  }

  startVideo() {
    this.medias.video = true;
    this.videoStart = true;
    this.changeDetector.detectChanges();
    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
        this.changeDetector.detectChanges();
      }
    );
  }

  stopVideo() {
    this.medias.video = false;
    try {
      this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
      this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    }
    catch {

    }
    finally {
      this.videoStart = false;
      this.changeDetector.detectChanges();
    }
  }

  checkImage() {
    if (this.videoElm) {
      const WIDTH = this.videoElm.nativeElement.clientWidth;
      const HEIGHT = this.videoElm.nativeElement.clientHeight;
      this.canvasElm.nativeElement.width = WIDTH;
      this.canvasElm.nativeElement.height = HEIGHT;

      const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

      ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
      const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
      const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })      

      if(!code || !code.data) {
        setTimeout(() => { this.checkImage(); }, 100)
      } else {
        if (!this.loaderService.loading) {
          this.errorMessage = '';
          this.authService.authenticate(code.data)
            .subscribe(
              (response) => {
                if (response) {
                  this.toggleVideoMedia();
                  this.router.navigate(['/logged/lotes-a-contar']);
                }
              },
              (error) => this.errorMessage = error
            );
        }
        setTimeout(() => { this.checkImage(); }, 100)
      }
    }
  }

  // loginFake() {
  //   this.authService.authenticate('')
  //     .subscribe(
  //       (response) => {
  //         if (response) {
  //           this.toggleVideoMedia();
  //           this.router.navigate(['/produtos']);
  //         }
  //       },
  //       (error) => this.errorMessage = error
  //     );
  // }
}
