<h1 mat-dialog-title>Count Type</h1>

<div mat-dialog-content>
  <mat-radio-group [(ngModel)]="selectedType">
    <mat-radio-button value="IA">IA</mat-radio-button>
    <br />
    <mat-radio-button value="Manual">Manual</mat-radio-button>
  </mat-radio-group>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-flat-button type="button" (click)="onClose()" color="primary">
    {{'buttons.ok' | translate}}
  </button>
</div>
