<h1 mat-dialog-title>{{'branchPlantSearch' | translate}}</h1>
<div mat-dialog-content>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col">
                <form>
                    <mat-form-field class="w-100">
                        <mat-label>{{'branchPlant' | translate}}</mat-label>
                        <input matInput type="text" class="text-uppercase" [(ngModel)]="filter"
                            (ngModelChange)="txtFilterChange($event)" id="txtFilter" name="txtFilter"
                            #fcFilter="ngModel">
                        <button class="clear-button" mat-button matSuffix mat-icon-button (click)="clearFilter()"
                            *ngIf="filter">
                            <mat-icon class="text-muted">close</mat-icon>
                        </button>
                        <mat-icon class="mr-2 text-muted" matSuffix *ngIf="!filter">search</mat-icon>
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="table-container">
                    <table mat-table [dataSource]="dataSource" matSort matSortDisableClear matSortActive="name"
                        matSortDirection="asc" class="w-100">
                        <!-- Branch Column -->
                        <ng-container matColumnDef="branch">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{branchPlants | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <div matRipple [matRippleColor]="'rgba(0,160,227,0.5)'" [matRippleRadius]="25"
                                    [matRippleCentered]="false" [matRippleUnbounded]="true">
                                    <span>{{element}}</span>
                                </div>
                            </td>
                        </ng-container>                       
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            [class.selected]="selectedBranch === row" (click)="select(row)">
                        </tr>
                    </table>
                    <ng-container *ngIf="!dataSource?.filteredData.length" class="m-5 p-5">
                        <p class="text-center text-muted m-5"><em>{{'messages.noResults' | translate}}</em></p>
                    </ng-container>
                </div>
                <mat-paginator [pageSize]="50"></mat-paginator>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-flat-button (click)="cancel()" class="mr-2" color="warn">{{'buttons.cancel' | translate}}</button>
    <button mat-flat-button [mat-dialog-close]="selectedBranch" [disabled]="!selectedBranch"
        color="primary">{{'buttons.ok' | translate}}</button>
</div>
<app-loader></app-loader>