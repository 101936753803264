import { AIIntegrationService as AIIntegrationService } from '../../services/ai-integration.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CycleCountCanister } from 'src/app/models/cycle-count-canister.model';
import { CycleCountListItem } from 'src/app/models/cycle-count-list-item.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CycleCountCanistersDetailsComponent } from './cycle-count-canisters-items/cycle-count-canisters-items.component';
import { CycleCountCanisterInsertTransactionLog } from 'src/app/models/cycle-count-canister-insert-transaction-log.model';

@Component({
  selector: 'app-cycle-count-canisters',
  templateUrl: './cycle-count-canisters.component.html',
  styleUrls: ['./cycle-count-canisters.component.scss']
})
export class CycleCountCanistersComponent implements OnInit {

  canisters: MatTableDataSource<CycleCountCanister>;
  columns: string[] = ['location'];
  transactionId: string;
  openedTransactionLog: CycleCountCanisterInsertTransactionLog;
  canisterLocation: string;
  appUrl = `https://zealous-forest-03eee380f.5.azurestaticapps.net`;
  // appUrl = `http://localhost:4201`;
  imageData: string | ArrayBuffer;
  thumbSrc: string | ArrayBuffer;

  constructor(
    private dataService: DataService,
    private aiIntegrationService: AIIntegrationService,
    public loaderService: LoaderService,
    public dialogRef: MatDialogRef<CycleCountCanistersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CycleCountListItem,
    public dialog: MatDialog,
    private authService: AuthService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.loadCanisters();
  }

  ngAfterViewInit(): void {
    if (this.data) {
      setTimeout(() => {
        this.loadCanisters();
      }, 100);
    }
  }

  loadCanisters(): void {
    this.dataService.getCanisters(this.data.id).subscribe((response) => {
      if (response) {
        this.canisters = new MatTableDataSource(response);
      }
    });
  }

  countCanister(item: CycleCountCanister): void {
    this.canisterLocation = item.location.trim();
    this.getOpenedTransactionLog();
  }

  close(): void {
    this.dialogRef.close();
  }

  private generateTransactionId(): void {
    this.transactionId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  getOpenedTransactionLog(): void {
    const cycleId = this.data.id;
    this.aiIntegrationService.getOpenedTransactionLog(cycleId, this.canisterLocation).subscribe((response) => { 
      this.openedTransactionLog = response
      if (!this.openedTransactionLog) {
        this.generateTransactionId();
        this.insertTransactionLog();
      } else {
        this.transactionId = this.openedTransactionLog.transactionId;
      }
      this.openAppUrl();
    });
  }

  insertTransactionLog(): void {
    const transactionLog = new CycleCountCanisterInsertTransactionLog();
    transactionLog.transactionId = this.transactionId;
    transactionLog.cycleId = this.data.id;
    transactionLog.userId = this.authService.user.id;
    transactionLog.canisterLocation = this.canisterLocation;
    this.aiIntegrationService.insertTransactionLog(transactionLog).subscribe((response) => {});
  }

  openAppUrl(): void {
    const parameters = `transactionId=${this.transactionId}&canisterLocation=${this.canisterLocation}`;
    const app = window.open(`${this.appUrl}?${parameters}`, '_blank');
    const checkClosedWindow = setInterval(() => {
      if (app.closed) {
        clearInterval(checkClosedWindow);
        this.loadCanisters();
      }
    }, 500);
  }

  openCountedItems(item: CycleCountCanister): void {
    this.data.location = item.location
    const dialogRef = this.dialog.open(CycleCountCanistersDetailsComponent, {
      data: this.data,
      width: '100vw'
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
}
