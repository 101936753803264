import { User } from './../models/user.model';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { LoaderService } from './loader.service';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiUrl;
  user: User;
  // token = null;
  jwtHelper = new JwtHelperService();

  isUnlocked = false;

  constructor(private _router: Router, private _http: HttpClient, private _loaderService: LoaderService,
    private _msalService: MsalService, private _translateService: TranslateService) { }

  authenticate(id: string): Observable<any> {
    return this._http.post<User>(`${this.apiUrl}/users`, { id })
      .pipe(
        map((response) => {
          if (response) {
            this.user = response;
            sessionStorage.setItem('userId', this.user.id);
            sessionStorage.setItem('userName', this.user.name);
            sessionStorage.setItem('userFirstName', this.user.firstName);
            this.isUnlocked = true;
            return true;
          } else {
            throwError(this._translateService.instant('messages.operationError'));
          }
        }),
        catchError(this.handleError));
  }

  isAuthenticated(): boolean {
    if (!this.user && sessionStorage.getItem('userId') != null) {
      this.user = new User();
      this.user.id = sessionStorage.getItem('userId');
      this.user.name = sessionStorage.getItem('userName');
      this.user.firstName = sessionStorage.getItem('userFirstName');
      this.isUnlocked = true;
    }
    // console.log(this.user);
    if (!this.user) {
      this._router.navigate(['/logged/lock']);
    }
    return this.user != null;
  }

  block(): void {
    this._loaderService.show();
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userName');
    this.isUnlocked = false;
    this.user = null;
    this._loaderService.hide();
    this._router.navigate(['/logged/lock']);
  }

  logout() {
    sessionStorage.clear();
    this._msalService.logout();
  }


  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned code ${error.status}`);
      console.log(error);
      if (error.status === 401) {
        return throwError(this._translateService.instant('messages.invalidUserId'));
      }
    }
    // return an observable with a user-facing error message
    return throwError(this._translateService.instant('messages.operationError'));
  }
}
