<mat-sidenav-container [hasBackdrop]="true" class="h-100">
    <mat-sidenav #drawer [mode]="'push'" class="p-3">
        <nav>
            <mat-nav-list *ngIf="authService.isUnlocked">
                <a class="text-white my-2" mat-list-item routerLink="lotes-a-contar" (click)="drawer.toggle()">
                    <mat-icon class="mr-1">filter_9_plus</mat-icon>
                    <span>{{'menu.lotsToCount' | translate}}</span>
                </a>
                <mat-divider></mat-divider>
                <a class="text-white my-2" mat-list-item routerLink="lotes-em-contagem" (click)="drawer.toggle()">
                    <mat-icon class="mr-1">format_list_numbered</mat-icon>
                    <span>{{'menu.countingLots' | translate}}</span>
                </a>
                <mat-divider></mat-divider>
                <a class="text-white my-2" mat-list-item routerLink="lotes-enviados" (click)="drawer.toggle()">
                    <mat-icon class="mr-1">send</mat-icon>
                    <span>{{'menu.sendedLots' | translate}}</span>
                </a>
                <mat-divider></mat-divider>
                <a class="text-white my-2" mat-list-item routerLink="lotes-arquivados" (click)="drawer.toggle()">
                    <mat-icon class="mr-1">history</mat-icon>
                    <span>{{'menu.archivedLots' | translate}}</span>
                </a>
                <mat-divider></mat-divider>
                <a class="text-white my-2" mat-list-item (click)="sync()">
                    <mat-icon class="mr-1">autorenew</mat-icon>
                    <span>{{'menu.sync' | translate}}</span>
                </a>
                <mat-divider></mat-divider>
                <a class="text-white my-2" mat-list-item (click)="block()">
                    <mat-icon class="mr-1">lock</mat-icon>
                    <span>{{'menu.lock' | translate}}</span>
                </a>
            </mat-nav-list>

            <mat-nav-list *ngIf="!authService.isUnlocked">
                <a class="text-white my-2" mat-list-item routerLink="" (click)="logout()">
                    <mat-icon class="mr-1">logout</mat-icon>
                    <span>{{'menu.logoff' | translate}}</span>
                </a>
            </mat-nav-list>
        </nav>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar class="mat-elevation-z4" color="primary">
            <mat-toolbar-row>
                <button mat-icon-button (click)="drawer.toggle()">
                    <mat-icon>menu</mat-icon>
                </button>
                <span class="ml-1"><span *ngIf="authService.isUnlocked">Inventory Count</span></span>
                <span class="spacer"></span>
                <div class="user-data" *ngIf="authService.isUnlocked">
                    <span class="mr-2">{{authService.user.firstName}}</span>
                    <button mat-icon-button (click)="block()">
                        <mat-icon>lock</mat-icon>
                    </button>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-loader></app-loader>