import { BranchSearchDialogComponent } from './../../branch-search-dialog/branch-search-dialog.component';
import { DataService } from './../../services/data.service';
import { CycleCountNotPointedItem } from './../../models/cycle-count-not-pointed-item.model';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BullSearchDialogComponent } from 'src/app/bull-search-dialog/bull-search-dialog.component';
import { LocationSearchDialogComponent } from 'src/app/location-search-dialog/location-search-dialog.component';
import { Bull } from 'src/app/models/bull.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NotPointedItemDialogData } from 'src/app/models/not-pointed-item-dialog-data';
import { TranslateService } from '@ngx-translate/core';
import { ImageViewerComponent } from 'src/app/image-viewer/image-viewer.component';
import { PhotoDialogV2Component } from 'src/app/photo-dialog-v2/photo-dialog-v2.component';

@Component({
  selector: 'app-cycle-count-not-pointed-item',
  templateUrl: './cycle-count-not-pointed-item.component.html',
  styleUrls: ['./cycle-count-not-pointed-item.component.scss']
})
export class CycleCountNotPointedItemComponent implements OnInit {

  branchError = false;
  error = false;  
  quantity: number;
  imageData: string;
  thumbSrc: string;

  @ViewChild('form', { static: true }) form: NgForm;
  @ViewChild('imageViewer', { static: false}) imageViewer: ImageViewerComponent;

  constructor(
    private _dataService: DataService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<CycleCountNotPointedItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotPointedItemDialogData,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;

    if (data && data.item) {
      if (!data.item.bull) {
        data.item.bull = new Bull();
      }
      // this.quantity = data.isRecount ? data.item.recountQuantity : data.item.countQuantity;
      this.quantity = data.item.toRecount ? data.item.recountQuantity : data.item.countQuantity;
    }
  }

  ngOnInit() {
    this.setThumbSrc();
  }  

  onSubmit() {
    this.branchError = false;
    this.data.item.branchPlant = '';
    if (this.validate()) {

      // if (this.data.isRecount) {
      //   this.data.item.recountQuantity = this.quantity;
      // } else {
      //   this.data.item.countQuantity = this.quantity;
      // }

      if (this.data.item.toRecount) {
        this.data.item.recountQuantity = this.quantity;
      } else {
        this.data.item.countQuantity = this.quantity;
      }

      this._dataService.getBranch(this.data.item.bull.id, `${this.data.item.location}${this.data.item.locationSuffix}`, this.data.item.lot).subscribe((response) => {
        if (response) {
          this.data.item.branchPlant = response;
          if (this.data.item.id) {
            this._dataService.updateNotPointedItem(this.data.item).subscribe((response) => {
              if (response) {
                this.data.item = response;
              }
              this.dialogRef.close(true);
            }, error => {
              this.error = true;
            });
          } else {
            this._dataService.addNotPointedItem(this.data.item).subscribe((response) => {
              if (response) {
                this.data.item = response;
              }
              this.dialogRef.close(true);
            }, error => {
              this.error = true;
            });
          }
        } else {
          this.branchError = true;
        }
      }, error => {
        console.log(error);
      });
    }
  }

  validate(): boolean {
    Object.keys(this.form.controls).forEach(field => this.form.controls[field].markAsTouched());
    const control = this.form.controls['txtQuantity'];
    if (!control.errors && control.value < 0) {
      control.setErrors({ 'min': true });
    }
    return this.form.valid;
  }

  openBullSearchDialog() {
    const dialogRef = this.dialog.open(BullSearchDialogComponent, {
      width: '500px',
      data: null
    });

    dialogRef.afterClosed().subscribe((result: Bull) => {
      if (result) {
        this.data.item.bull.id = result.id;
        this.data.item.bull.code = result.code;
        this.data.item.bull.name = result.name;
        this.data.item.branchPlant = '';
      }
    });
  }

  openLocationSearchDialog() {
    const dialogRef = this.dialog.open(LocationSearchDialogComponent, {
      width: '500px',
      data: this.data.item.cycleNumber
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.data.item.location = result;
      }
    })
  }  

  cancel(): void {
    this.dialogRef.close();
  }

  openPhotoDialog(): void {

    const dialogRef = this.dialog.open(PhotoDialogV2Component, {
      autoFocus: false,
      width: '548px',
      height: '680px'
    });    

    dialogRef.afterClosed().subscribe((result: string | boolean) => {
      if (result) {
        this.imageData = result as string;
        this.data.item.imageBase64 = result as string;
        // if (this.data.isRecount) {
        //   this.data.item.recountImageUrl = null;
        // } else {
        //   this.data.item.countImageUrl = null;
        // }
        if (this.data.item.toRecount) {
          this.data.item.recountImageUrl = null;
        } else {
          this.data.item.countImageUrl = null;
        }
        this.setThumbSrc();
      }
    });
  }

  deleteImage(): void {
    this.imageData = null;
    this.data.item.imageBase64 = null;
    // if (this.data.isRecount) {
    //   this.data.item.recountImageUrl = null;
    // } else {
    //   this.data.item.countImageUrl = null;
    // }
    if (this.data.item.toRecount) {
      this.data.item.recountImageUrl = null;
    } else {
      this.data.item.countImageUrl = null;
    }
  }

  setThumbSrc(): void {
    // if (this.data.isRecount) {
    //   this.thumbSrc = this.data.item.recountImageUrl ? `${this.data.item.recountImageUrl}&${new Date().getTime()}` : this.imageData;
    // } else {
    //   this.thumbSrc = this.data.item.countImageUrl ? `${this.data.item.countImageUrl}&${new Date().getTime()}` : this.imageData;
    // }
    if (this.data.item.toRecount) {
      this.thumbSrc = this.data.item.recountImageUrl ? `${this.data.item.recountImageUrl}&${new Date().getTime()}` : this.imageData;
    } else {
      this.thumbSrc = this.data.item.countImageUrl ? `${this.data.item.countImageUrl}&${new Date().getTime()}` : this.imageData;
    }
  }

  viewImage(): void {
    this.imageViewer.show();
  }

}
