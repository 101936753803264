<main class="container-fluid h-100">
    <div class="row align-items-center justify-content-center h-100">
        <div class="col-12 col-md-6 text-center text-white d-flex flex-column align-items-center h-100">
            <h1 class="mt-auto">Inventory Count</h1>
            <div class="my-auto">                
                <mat-spinner></mat-spinner>                
            </div>
            <img class="img-fluid d-block mt-auto mb-2 logo" src="/assets/images/urus-white-logo.png">
        </div>
    </div>
</main>