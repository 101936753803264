import { Bull } from './bull.model';
export class CycleCountNotPointedItem {
    id: string;
    countQuantity: number;
    recountQuantity: number;
    lot: string;
    branchPlant: string;    
    location: string;
    locationSuffix: string;
    cycleNumber: number;
    bull: Bull;
    countImageUrl: string;
    recountImageUrl: string;
    imageBase64: string;
    toRecount: boolean;
}