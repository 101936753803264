import { DataService } from './../../services/data.service';
import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { StartCycleCountRequest } from './../../models/start-cylce-count-request.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { CycleCountListItem } from 'src/app/models/cycle-count-list-item.model';
import { ErrorDialogComponent } from 'src/app/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cycle-counts-to-start-list',
  templateUrl: './cycle-counts-to-start-list.component.html',
  styleUrls: ['./cycle-counts-to-start-list.component.scss']
})
export class CycleCountsToStartListComponent implements OnInit {

  filter: string;
  displayedColumns: string[] = ['id', 'description'];
  dataSource: MatTableDataSource<CycleCountListItem>;
  subscription: Subscription;
  filterChange: Subject<string> = new Subject<string>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private _dataService: DataService,
    private _authService: AuthService,
    private _router: Router,
    private _translateService: TranslateService,
    public dialog: MatDialog) {
    this.filterChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe((value: string) => {
        this.dataSource.filter = value ? value.trim().toLowerCase() : '';
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      });
  }

  ngOnInit() {
    this.loadData();
  }

  txtFilterChange(value: string) {
    this.filterChange.next(value);
  }

  clearFilter() {
    this.filter = '';
    this.filterChange.next(this.filter);
  }

  loadData() {
    this.subscription = this._dataService.getCycleCountsToStart().subscribe((response) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  startCount(item: CycleCountListItem) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: `<p>
          ${this._translateService.instant(item.isRecount ? 'messages.startRecountMessageLine1' : 'messages.startCountMessageLine1', {
          itemId: item.id,
          itemDescription: item.description
        })}
          </strong>?</p>
          <p>${this._translateService.instant('messages.startCountMessageLine2')}</p>`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const ccStartRequest = new StartCycleCountRequest(item.id, this._authService.user.id);
        this._dataService.startCycleCount(ccStartRequest).subscribe(() => {
          this._router.navigateByUrl(`/logged/lotes-em-contagem/${item.id}`);
        }, (error) => {
          if (error.status === 403) {
            const messageDialog = this.dialog.open(ErrorDialogComponent, {
              data: {
                message: this._translateService.instant('reservedLot', {itemId: item.id, itemDescription: item.description})
              }
            });
            messageDialog.afterClosed().subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }
}
