import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CycleCountItemsComponent } from '../cycle-counts/cycle-count-items/cycle-count-items.component';
import { CycleCountListItem } from '../models/cycle-count-list-item.model';
import { CycleCountCanistersComponent } from '../cycle-counts/cycle-count-canisters/cycle-count-canisters.component';

@Component({
  selector: 'app-count-type-dialog',
  templateUrl: './count-type-dialog.component.html',
  styleUrls: ['./count-type-dialog.component.scss']
})
export class CountTypeDialogComponent {
  
  selectedType: string = 'IA';

  constructor(public dialogRef: MatDialogRef<CountTypeDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: CycleCountListItem
  ) {}

  onClose(): void {
    if (this.selectedType !== 'IA') {
      this.openCycleCountItems();
    } else {
      this.openCycleCountCanisters()
    }
    this.dialogRef.close(this.selectedType);
  }

  openCycleCountItems() {
    const dialogRef = this.dialog.open(CycleCountItemsComponent, {
      data: this.data,
      autoFocus: false,
      width: '100vw',
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openCycleCountCanisters() {
    const dialogRef = this.dialog.open(CycleCountCanistersComponent, {
      data: this.data,
      autoFocus: false,
      width: '100vw',
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}